import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default () => (
  <Container className="footer">
    <Row className="mt-0 pt-2 pb-2 text-center border-top border-dark text-muted">
      <Col className="text-center text-md-right">
        <small>
          A <a href="https://koaandco.com/" className="text-muted">koa &amp; co</a> production
        </small>
      </Col>
    </Row>
  </Container>
);
