import React from 'react';
import {
  Container, Row, Col
} from 'reactstrap';

export default () => (
  <Container className="header">
    <Row>
      <Col className="text-center mt-5 mb-5 justify-content-center">
        <h1 className="font-weight-light text-light text-shadow border-bottom pb-2 mb-1">Wedeography</h1>
        <h6 className="pl-1 pr-1 text-light text-shadow font-weight-light">diving right into the action</h6>
      </Col>
    </Row>
  </Container>
);
