import React from 'react';
import {
  Navbar,
  Nav,
  NavItem,
} from 'reactstrap';
import { Link } from 'gatsby';

export default () => (
  <div>
    <Navbar color="transparent" dark expand="md" fixed="top">
      <div className="d-flex justify-content-center w-100 align-items-center">
        <Nav className="text-center flex-row" navbar>
          <NavItem className="pl-1 pl-sm-2 pr-1 pr-sm-2">
            <Link to="/" className="nav-link" activeStyle={{
              color: "white",
              textShadow: "1px 1px 1px #000000"
            }}>
              Videos
            </Link>
          </NavItem>
          <NavItem className="pl-1 pl-sm-2 pr-1 pr-sm-2">
            <Link to="/about" className="nav-link" activeStyle={{
              color: "white",
              textShadow: "1px 1px 1px #000000"
            }}>
              About
            </Link>
          </NavItem>
          <NavItem className="pl-1 pl-sm-2 pr-1 pr-sm-2">
            <Link to="/contact" className="nav-link" activeStyle={{
              color: "white",
              textShadow: "1px 1px 1px #000000"
            }}>
              Contact
            </Link>
          </NavItem>
        </Nav>
      </div>
    </Navbar>
  </div>
);
