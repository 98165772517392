import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {
  Card, Container, Row, Col
} from 'reactstrap';

import Navigation from './navigation';
import Header from './header';
import Footer from './footer';

import bgImg from '../images/background.jpg'

export default ({ children, noPadding, hugeMargin }) => {
  const className = noPadding ? "m-0 p-0" : "p-1 p-md-5";
  const title = "Wedeography";
  const description = "Videos, marketing.";
  const classNames = hugeMargin ? 'mt-huge mb-3' : 'mb-3';
  return (
    <div className="base" style={{ backgroundImage: `url(${bgImg})` }}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={title}
        titleTemplate={`%s | ${title}`}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: description,
          },
        ]
        }
      />
      <Navigation />
      <Header />
      <Container className={classNames}>
        <Row noGutters>
          <Col className="p-0 m-0">
            <Card className={`bg-light text-dark border-0 shadow-lg rounded ${className}`}>
              {children}
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
};

export const propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  hugeMargin: PropTypes.bool
};

export const defaultProps = {
  noPadding: false,
  hugeMargin: false
};
